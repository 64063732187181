import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#EBE3DE',
      neutralLight2: '#D3CECB',
      neutralLight1: '#C4BCB8',
      neutralDark4: '#9D9B9A',
      neutralDark3: '#746E6B',
      neutralDark2: '#4E5772',
      neutralDark1: '#131B35',
      neutralDark0: '#000000',
      primaryLight1: '#4C9BA8',
      primaryDark1: '#337F8B',
      dangerLight1: '#EB5757',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Barlow', serif",
    paragraph: "'SourceSansPro', sans-serif",
    signature: "'ReenieBeanie'",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
